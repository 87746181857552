import React, { Fragment } from "react";
import { Link } from "gatsby";
import Footer from "../homepage/footer";
import Lottie from "react-lottie";
import logoAnimationData from "../homepage/logo_animation.json";
import Seo from "../../seo";
import "./styles.scss";
import { speakersDetails } from "../homepage/allstate.js";
import brand from "../homepage/images/logo.svg";
import github from "../homepage/images/github-speaker.svg";
import linkedin from "../homepage/images/linkedin-speaker.svg";
import twitter from "../homepage/images/twitter-speaker.svg";
import speakersIllustration from "../homepage/images/speakers-illustration.svg";
import btnArrow from "../homepage/images/btn-arrow.svg";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprise-conf.jpg",
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: logoAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const SpeakerDetails = props => {
  const speakersUrl = props.pageContext.slug;
  const currentSpeakersFilter = speakersDetails.filter(b => b.url === speakersUrl);
  const currentSpeakers = currentSpeakersFilter[0];
  if (!currentSpeakers) {
    if (typeof window !== undefined) {
      window.location.href = "/404";
    }
  }
  return (
    <Fragment>
      <Seo
        title={currentSpeakers.metaTags.title}
        description={currentSpeakers.metaTags.description}
        meta={ogImage}
        canonicalLink={currentSpeakers.metaTags.canonicalUrl}
      />
      <section className="greenBgColor">
        <div className="mainWrapperSpeakers mainGridWrapperEnterprise removePaddBottom">
          <div>
            <div className="leftSidebarWrapper">
              <Link to="/enterprisegraphql/2020/">
                <div className="brand">
                  <Lottie
                    options={defaultOptions}
                    width={80}
                    style={{ margin: 0, marginLeft: "-10px" }}
                  />
                  <img src={brand} alt="Brand" />
                </div>
              </Link>
              <div className="mobile-dates visibleMobileEnterprise">
                21st &amp; 22nd
                <br />
                Oct’20
              </div>
            </div>
          </div>
          <div className="zIndexOne">
            <div className="speakersTopBanner">
              <div className="speakersImg">
                <img src={currentSpeakers.speakersImg} alt={currentSpeakers.speakersName} />
              </div>
              <div
                className={
                  "hiddenMobile" +
                  (currentSpeakers.twitterLink ||
                  currentSpeakers.linkedinLink ||
                  currentSpeakers.githubLink
                    ? " alignSelfBottom"
                    : "")
                }
              >
                {currentSpeakers.about ? (
                  <div>
                    <div className="sectionTitleEnterprise">
                      About {currentSpeakers.speakersName}
                    </div>
                    {currentSpeakers.about.lists.map((list, index) => {
                      return (
                        <div key={index} className="descEnterprise">
                          {list}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {currentSpeakers.twitterLink ||
                currentSpeakers.linkedinLink ||
                currentSpeakers.githubLink ? (
                  <div className="speakersSocialWrapper">
                    {currentSpeakers.twitterLink ? (
                      <a
                        href={currentSpeakers.twitterLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="speakerSocialImg">
                          <img src={twitter} alt="Twitter" />
                        </div>
                      </a>
                    ) : null}
                    {currentSpeakers.linkedinLink ? (
                      <a
                        href={currentSpeakers.linkedinLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="speakerSocialImg">
                          <img src={linkedin} alt="Linkedin" />
                        </div>
                      </a>
                    ) : null}
                    {currentSpeakers.githubLink ? (
                      <a
                        href={currentSpeakers.githubLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="speakerSocialImg">
                          <img src={github} alt="Github" />
                        </div>
                      </a>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="grayBgColor">
        <img
          className="speakersDetailsIllustration"
          src={speakersIllustration}
          alt="Illustration"
        />
        <div className="mainWrapperSpeakers">
          <div className="mainGridWrapperEnterprise addPaddTopSection speakersBottomSection">
            <div className="zIndexOne">
              <div className="rotate">
                <div className="descEnterprise">{currentSpeakers.time}</div>
                <div className="sectionTitleEnterprise">{currentSpeakers.speakersName}</div>
              </div>
              {currentSpeakers.about ? (
                <div className="visibleMobileEnterprise">
                  {currentSpeakers.about.lists.map((list, index) => {
                    return (
                      <div key={index} className="descEnterprise">
                        {list}
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {currentSpeakers.twitterLink ||
              currentSpeakers.linkedinLink ||
              currentSpeakers.githubLink ? (
                <div className="speakersSocialWrapper visibleMobileEnterprise">
                  {currentSpeakers.twitterLink ? (
                    <a href={currentSpeakers.twitterLink} target="_blank" rel="noopener noreferrer">
                      <div className="speakerSocialImg">
                        <img src={twitter} alt="Twitter" />
                      </div>
                    </a>
                  ) : null}
                  {currentSpeakers.linkedinLink ? (
                    <a
                      href={currentSpeakers.linkedinLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="speakerSocialImg">
                        <img src={linkedin} alt="Linkedin" />
                      </div>
                    </a>
                  ) : null}
                  {currentSpeakers.githubLink ? (
                    <a href={currentSpeakers.githubLink} target="_blank" rel="noopener noreferrer">
                      <div className="speakerSocialImg">
                        <img src={github} alt="Github" />
                      </div>
                    </a>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="enterprisewd65 zIndexOne">
              {currentSpeakers.talkDetails.map((talks, index) => {
                return (
                  <div
                    className={
                      "enterpriseTalkDetails" +
                      (currentSpeakers.talkDetails.borderBottom ? " enterpriseBorBottomGray" : "")
                    }
                    key={index}
                  >
                    <div className="descEnterprise">{currentSpeakers.talkTitleType}</div>
                    <h1 className="sectionTitleEnterprise">{talks.title}</h1>
                    <div className="descTakeAwayWrapper">
                      <div>
                        {talks.description ? (
                          <Fragment>
                            {talks.description.lists.map((list, listIndex) => {
                              return (
                                <div key={listIndex} className="descEnterprise">
                                  {list}
                                </div>
                              );
                            })}
                            {talks.description.subLists ? (
                              <ul className="descEnterprise listsPaddTop">
                                {talks.description.subLists.map((subList, subIndex) => {
                                  return (
                                    <li key={subIndex}>
                                      <div className="grayCircle"></div>
                                      {subList}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : null}
                            {talks.description.listsTwo
                              ? talks.description.listsTwo.map((list, listIndex) => {
                                  return (
                                    <div key={listIndex} className="descEnterprise">
                                      {list}
                                    </div>
                                  );
                                })
                              : null}
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="eventsBtnWrapper">
                <div className="enterpriseLine darkLine"></div>
                <Link to="/enterprisegraphql/2020/#speakers">
                  <button className="eventsBtn">
                    <img src={btnArrow} alt="Arrow" />
                    Back to Speakers
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="buttonWrapper buttonPos">
        <Link to={currentSpeakers.videoPageLink}>
          <button className="commonBtnEnterprise">Watch Video</button>
        </Link>
      </div>
      <Footer bgColor="grayBgColor" />
    </Fragment>
  );
};

export default SpeakerDetails;
